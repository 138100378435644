import { useDialogContext } from '@locked-dobby/library-react-dialog';
import profileClient from 'api/profileClient';
import dayjs from 'dayjs';
import { useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { detectPlatformIdentity } from 'script/util/platform-utils';

import SetMarketingInfoDialog from './setMarketingInfoDialog';

const MarketingComponent = ({ children }) => {
  const isRequest = useRef(false);

  const { confirm, showDialog, toast } = useDialogContext();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hasMarketingConsent =
    queryParams.get('hasMarketingConsent') === 'false';

  const checkMarketingAgree = useCallback(async () => {
    if (!hasMarketingConsent) return;

    if (!detectPlatformIdentity().isPc) {
      return await profileClient
        .get('/account/consent/marketing')
        .then(async (res) => {
          if (!res.data.marketingAgreeDate) {
            if (
              await confirm({
                message:
                  '인어교주해적단의 이벤트, 할인 혜택 등을\n푸시 알림으로 보내드리겠습니다.\n앱 푸시 알림에 수신 동의하시겠습니까?',
                title:
                  '‘인어교주해적단’에서\n광고성 정보 알림을 보내고자 합니다.',
              })
            ) {
              await profileClient
                .patch('/account/consent/marketing', {
                  marketingNotification: true,
                })
                .then(async (res) => {
                  const { data } = res;
                  if (data) {
                    toast({
                      message: `인어교주해적단의 마케팅 알림 수신에 동의 하셨습니다.(${dayjs(data.marketingAgreeDate).format('YYYY-MM-DD')})`,
                    });
                  }
                  const dialog = await showDialog(<SetMarketingInfoDialog />);
                  if (dialog.result) {
                    toast({
                      message: '적용되었습니다.',
                    });
                  }
                })
                .catch((err) => {
                  toast({
                    message: err.response?.data.message || err.message,
                  });
                });
            } else {
              await profileClient
                .patch('/account/consent/marketing', {
                  marketingNotification: false,
                })
                .then(async (res) => {
                  const { data } = res;
                  toast({
                    message: `인어교주해적단의 마케팅 알림 수신을 거부하셨습니다.\n마이페이지 > 알림설정에서 변경하실 수 있습니다.(${dayjs(data.marketingAgreeDate).format('YYYY-MM-DD')})`,
                  });
                })
                .catch((err) => {
                  toast({
                    message: err.response?.data.message || err.message,
                  });
                });
            }
          }
        })
        .catch((err) => {
          console.error(err);
          toast({
            message: err.response?.data.message || err.message,
          });
        });
    }
  }, [confirm, hasMarketingConsent, toast, showDialog]);

  useEffect(() => {
    if (!isRequest.current) {
      isRequest.current = true;
      checkMarketingAgree();
    }
  }, [checkMarketingAgree]);

  return <div>{children}</div>;
};

export default MarketingComponent;
